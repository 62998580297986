<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 1.00049C11.866 1.00049 15 4.13449 15 8.00049C15 11.8665 11.866 15.0005 8 15.0005C4.13401 15.0005 1 11.8665 1 8.00049C1 4.13449 4.13401 1.00049 8 1.00049Z"
      fill="white"
      stroke="#E5E5E5"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.1098 7.20703H8V8.89497H10.3484C10.2473 9.44042 9.93983 9.90257 9.47769 10.212V11.3069H10.8879C11.7131 10.5472 12.1891 9.42852 12.1891 8.09959C12.1891 7.79017 12.1613 7.49265 12.1098 7.20703Z"
      fill="#4285F4"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00009 12.3641C9.17827 12.3641 10.166 11.9734 10.888 11.3069L9.47778 10.2121C9.08703 10.4739 8.5872 10.6286 8.00009 10.6286C6.86356 10.6286 5.90158 9.86099 5.55844 8.82959H4.10059V9.96017C4.8186 11.3863 6.29431 12.3641 8.00009 12.3641Z"
      fill="#34A853"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.55846 8.82929C5.47119 8.56747 5.4216 8.2878 5.4216 8.0002C5.4216 7.71259 5.47119 7.43292 5.55846 7.17111V6.04053H4.10061C3.80507 6.62962 3.63647 7.29606 3.63647 8.0002C3.63647 8.70433 3.80507 9.37078 4.10061 9.95987L5.55846 8.82929Z"
      fill="#FBBC05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.00009 5.37226C8.64075 5.37226 9.21596 5.59242 9.66819 6.02482L10.9198 4.77325C10.1641 4.06912 9.17629 3.63672 8.00009 3.63672C6.29431 3.63672 4.8186 4.61457 4.10059 6.04069L5.55844 7.17126C5.90158 6.13986 6.86356 5.37226 8.00009 5.37226Z"
      fill="#EA4335"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThirdGoogleIcon'
}
</script>
