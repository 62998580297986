<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8 0.000488281C5.87827 0.000488281 3.84344 0.843343 2.34315 2.34363C0.842855 3.84393 0 5.87876 0 8.00049C0 10.1222 0.842855 12.1571 2.34315 13.6573C3.84344 15.1576 5.87827 16.0005 8 16.0005C10.1217 16.0005 12.1566 15.1576 13.6569 13.6573C15.1571 12.1571 16 10.1222 16 8.00049C16 5.87876 15.1571 3.84393 13.6569 2.34363C12.1566 0.843343 10.1217 0.000488281 8 0.000488281Z"
      fill="#4680C2"
    />
    <path
      d="M12.775 5.43495C12.7934 5.37455 12.8022 5.31855 12.7998 5.26855C12.7938 5.12375 12.6942 5.01855 12.4598 5.01855H11.4126C11.1482 5.01855 11.0262 5.17855 10.955 5.33895C10.955 5.33895 10.3022 6.68255 9.5498 7.56855C9.3058 7.82495 9.1818 7.81855 9.0498 7.81855C8.979 7.81855 8.8002 7.73295 8.8002 7.49815V5.42415C8.8002 5.14655 8.731 5.01855 8.5074 5.01855H6.6478C6.485 5.01855 6.4002 5.14655 6.4002 5.27496C6.4002 5.54176 6.7594 5.60575 6.8002 6.35335V7.80255C6.8002 8.15455 6.739 8.21855 6.607 8.21855C6.251 8.21855 5.5502 7.01855 5.081 5.44576C4.9794 5.13616 4.8778 5.01855 4.6134 5.01855H3.5562C3.251 5.01855 3.2002 5.16815 3.2002 5.32815C3.2002 5.61655 3.4402 7.17575 4.7502 8.96855C5.7002 10.2686 6.9518 11.0186 8.0598 11.0186C8.731 11.0186 8.7998 10.8478 8.7998 10.581V9.39215C8.8002 9.07175 8.87339 9.01855 9.087 9.01855C9.2394 9.01855 9.5502 9.11855 10.1502 9.81855C10.8422 10.6258 10.9678 11.0186 11.3646 11.0186H12.4118C12.655 11.0186 12.7946 10.9166 12.8002 10.7186C12.8014 10.6682 12.7942 10.6118 12.7778 10.549C12.7002 10.3186 12.3442 9.75535 11.9002 9.21855C11.6542 8.92135 11.4114 8.62695 11.2998 8.46695C11.225 8.36255 11.1966 8.28895 11.2002 8.21855C11.2038 8.14455 11.2422 8.07415 11.2998 7.97575C11.2894 7.97575 12.643 6.07535 12.775 5.43495Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThirdVkIcon'
}
</script>
