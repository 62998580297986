<template>
  <div class="account-list__container">
    <template v-if="!isShowSingleAccountList">
      <div class="multi-account__title">
        {{ isLoginAccount ? langText.SHEIN_KEY_PWA_24600 : langText.SHEIN_KEY_PWA_34385 }}
      </div>
      <p class="multi-account__text">
        {{ isLoginAccount ? langText.SHEIN_KEY_PWA_34391 : langText.SHEIN_KEY_PWA_34390 }}
      </p>
      <ul class="multi-account__list">
        <li
          v-for="item in accountList"
          :key="item?.uid"
          class="multi-account__list_item"
          :class="{ 'multi-account__item_disable': isLoginAccount == item?.uid }"
          @click="handleSwitch(item)"
        >
          <div class="multi-account__avatar">
            <AccountIcon class="account-icon" />
            <ThirdLoginIcon
              class="third-login__icon"
              :aliasType="item.alias_type"
            />
          </div>
          <span class="multi-account__desc"> {{ item?.desensitize_alias }} </span>
          <div style="height: 16px;">
            <SelectedIcon v-if="isLoginAccount == item?.uid" />
            <template v-if="isLoginAccount != item?.uid">
              <MoreIcon 
                v-if="!isRemove"
                :class="moreClass"
              />  
              <div
                v-if="isRemove"
                class="remove__link"
                @click.stop="handleShowDeleteModal(item?.uid)"
              >
                {{ langText.SHEIN_KEY_PWA_24593 }}
              </div>
            </template>
          </div>
        </li>
      </ul>
      <div
        v-if="accountList.length >= 5"
        class="multi-account__limit"
      >
        {{ limitText }}
      </div>
    </template>

    <template v-if="isShowSingleAccountList">
      <div class="single-account__title">
        <SheinLogoIcon v-if="!publicLocalData.IS_RW" />
        <RomweLogoIcon v-if="publicLocalData.IS_RW" />
      </div>
      <div class="single-account__content">
        <span class="single-account__last_login">
          {{ langText.SHEIN_KEY_PWA_24634 }}
        </span>
        <div class="single-account__avatar">
          <AccountIcon class="account-icon" />
          <ThirdLoginIcon
            class="third-login__icon"
            :aliasType="accountList?.[0]?.alias_type"
          />
        </div>
        <span class="single-account__alias">{{ accountList?.[0]?.desensitize_alias }} </span>
      </div>
      <s-button
        class="single-account__signIn_btn"
        :type="['primary']"
        width="100%"
        @click="handleToSignIn(accountList?.[0])"
      >
        {{ langText.SHEIN_KEY_PWA_24592 }}
      </s-button>
    </template>
    <ClientOnly>
      <LazyMount mount-prop-name="isShowModal">
        <LazyMountModal :isShowModal="deleteAccountValue ? true : false">
          <DeleteAccountModal 
            ref="deleteAccountRef" 
            :langText="langText"
            @remove="handleRemoveItem"
            @close="handleCloseDeleteModal"
          />
        </LazyMountModal>
      </LazyMount>
      <ThirdLoginFunction ref="thirdLoginRef" />
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'
import { useStore, useEventBus } from '@login/hooks/utils'
import AccountIcon from '@login/components/Icon/AccountIcon.vue'
import SelectedIcon from '@login/components/Icon/SelectedIcon.vue'
import MoreIcon from '@login/components/Icon/MoreIcon.vue'
import SheinLogoIcon from '@login/components/Icon/SheinLogoIcon.vue'
import RomweLogoIcon from '@login/components/Icon/RomweLogoIcon.vue'
import ThirdLoginIcon from './ThirdLoginIcon.vue'
import DeleteAccountModal from './DeleteAccountModal.vue'
import ThirdLoginFunction from '@login/components/ThirdLoginFunction'
import { template } from '@shein/common-function'
import { switchLoginSer, removeAccountSer } from '@login/services/multiAccount.js'
import { getEmailSubscribe } from '@login/services/subScribe.js'
import { formatAccountList, formatThirdLoginType } from '@login/utils/index.js'
import { urlRedirection } from '@login/util'
import { clickRemoveAccount, clickAccount, clickLoginSignin, clickSigninThirdParty, clickSigninThirdPartyResult, exposeSingleAccount, exposeMultiAccount } from '@login/utils/loginAnalysis/multiAccount.js'
import { Metric_Login_Mutil_Click, Metric_Login_Single_Click, Metric_Login_Click_Third_Total, Metric_Login_Detail_Click_Result_Total, Metric_Login_Click_Third_Result_Total } from '@login/utils/monitorLog.js'
import { formatAccountChannel } from '@login/utils/loginAnalysis/formatData.js'
import ClientOnly from 'vue-client-only'

const props = defineProps({
  isRemove: {
    type: Boolean,
    default: false
  }
})

const store = useStore()
const evt = useEventBus()

const deleteAccountRef = ref(null)
const thirdLoginRef = ref(null)
const deleteAccountValue = ref('')
const loginType = ref([3, 4, 5, 6, 7, 8, 9])

const langText = computed(() => store.state.login.langText)
const newUIStates = computed(() => store.state.login.newUIStates)
const instancename = computed(() => store.state.login.instancename)
const isAsComponent = computed(() => store.state.login.isAsComponent)
const showIndex = computed(() => store.state.login.showIndex)
const commonAbt = computed(() => store.state.login.commonAbt)
const accountList = computed(() => {
  const accountListValue = store.state.login?.accountList
  return formatAccountList(accountListValue)
})
const publicLocalData = computed(() => store.state.login.publicLocalData)

const moreClass = computed(()=> {
  return ['pwar', 'rwmar']?.includes(publicLocalData.value?.SiteUID) ? 'flip-horizontal' : ''
})

const isShowSingleAccount = computed(() => accountList.value?.length == 1)

const isShowSingleAccountList = computed(() => {
  if (accountList.value?.length == 1 && isShowSingleAccount.value && !newUIStates.value?.isSwitchAccount && !props.isRemove) return true
  return false
})

const isLoginAccount = computed(() => store.state.login?.preloadData?.uid)

const limitCount = computed(() => store.state.login?.preloadData?.multiAccountLimit)

const limitText = computed(() => {
  if(limitCount.value < 5) return ''
  return template( limitCount.value, langText.value?.SHEIN_KEY_PWA_31405) 
})

watch(showIndex, (v) => {
  if(v){
    setTimeout(() => {
      exposeEvents()
    }, 200)
  }
})

const handleShowDeleteModal = (val) => {
  if (accountList.value.length > 1) {
    clickRemoveAccount()
  }
  deleteAccountValue.value = val
  nextTick(() => {
    deleteAccountRef.value.showDeleteAccountModal = true
  })
}

const handleRemoveItem = () => {
  const indexDelete = accountList.value?.findIndex(v => v.uid == deleteAccountValue.value)
  if (indexDelete !== -1) {
    accountList.value?.splice(indexDelete, 1)
  }
  store.commit('login/removeAccountListItem', deleteAccountValue.value)
  deleteAccountValue.value = ''
  if (accountList.value?.length == 0) {
    try {
      const params = {
        historyAccountList: []
      }
      removeAccountSer(params)
    } catch (error) {
      console.error(error + '')
    }
  }
}

const handleCloseDeleteModal = () => {
  deleteAccountValue.value = ''
}

const handleSwitch = (item) => {
  if (props.isRemove) return
  const { uid, is_remember, alias_type, desensitize_alias, encryption_alias, area_code, area_abbr } = item || {}
  if (uid == isLoginAccount.value) return
  clickAccount({ alias_type, is_remember })
  Metric_Login_Mutil_Click({ alias_type, is_remember, sub_site: publicLocalData.value?.SiteUID })
  if (is_remember == '1') {
    evt.$emit('event-loading', true)
    const params = {
      alias_type,
      uid,
      encryption_alias,
      isSwitchUid: isLoginAccount.value || ''
    }
    if (alias_type == 2) Object.assign(params, { area_abbr, area_code })
    requestSwitchLogin(params, item)
    return
  }
  if (!handleThirdLogin(alias_type)) return
  handleToContinue(item)
}

const requestSwitchLogin = async (params, val) => {
  try {
    if (loginType.value?.includes(params.alias_type)) {
      clickSigninThirdParty({ alias_type: params.alias_type })
      Metric_Login_Click_Third_Total({ account_type: formatAccountChannel(params.alias_type), sub_site: publicLocalData.value?.SiteUID })
    }
    const data = await switchLoginSer(params)
    const { code } = data || {}
    if ([1, 2].includes(params.alias_type)) {
      clickLoginSignin({ type: params.alias_type, code })
      Metric_Login_Detail_Click_Result_Total({ account_type: formatAccountChannel(params.alias_type), sub_site: publicLocalData.value?.SiteUID, result: code == 0 ? 'success' : 'failed', error: code })
    }
    if (loginType.value?.includes(params.alias_type)) {
      clickSigninThirdPartyResult({ alias_type: params.alias_type })
      Metric_Login_Click_Third_Result_Total({ account_type: formatAccountChannel(params.alias_type), sub_site: publicLocalData.value?.SiteUID || '', result: code == 0 ? 'success' : 'failed', error: code, is_register: '0' })
    }
    if (code == 0) {
      setTimeout(function () {
        urlRedirection(false, data)
      }, 300)
      return
    }
    evt.$emit('event-loading', false)
    if (!handleThirdLogin(params?.alias_type, true)) return
  } catch (err) {
    console.error(err + '')
  }
  handleToContinue(val, true)
}

const handleThirdLogin = (alias_type, isSwitch = false) => {
  if (loginType.value?.includes(alias_type)) {
    const thirdType = formatThirdLoginType(alias_type)
    if (isSwitch) {
      store.commit('login/setnewUIStates', { isSwitch })
    }
    if (thirdType) {
      thirdLoginRef.value?.handleRelationButton(thirdType)
      return false
    }
  }
  return true
}

const handleToContinue = async (val, isSwitch = false) => {
  const { alias_type, desensitize_alias, encryption_alias, area_code, area_abbr } = val || {}
  const showParams = {
    showType: alias_type == 1 ? 'encryptionEmail' : 'encryptionPhone',
    alias_type: alias_type == 1 ? 7 : 8,
    type: 'login',
    desensitize_alias,
    encryption_alias,
    code: area_code || '',
    abbr: area_abbr || '',
    isSwitch
  }
  if(alias_type == 1 && commonAbt.value?.EmailLoginSubscribe == 'yes'){
    evt.$emit('event-loading', true)
    const { info } = await getEmailSubscribe({ encryption_alias }) || {}
    const subscribe_status = info?.subscribe_status || ''
    Object.assign(showParams, { subscribe_status })
  }
  evt.$emit('event-loading', false)
  store.commit('login/setnewUIStates', showParams)
}

const handleToSignIn = (item) => {
  const { uid, is_remember, alias_type, desensitize_alias, encryption_alias, area_code, area_abbr } = item || {}
  clickAccount({ alias_type, is_remember })
  Metric_Login_Single_Click({ alias_type, is_remember, sub_site: publicLocalData.value?.SiteUID })
  if (is_remember == '1') {
    evt.$emit('event-loading', true)
    const params = {
      alias_type,
      uid,
      encryption_alias
    }
    if (alias_type == 2) Object.assign(params, { area_abbr, area_code })
    requestSwitchLogin(params, item)
    return
  }
  if (!handleThirdLogin(alias_type)) return
  handleToContinue(item)
}

const exposeEvents = () => {
  const historyAccountList = accountList.value
  if(historyAccountList?.length == 1){
    try{
      historyAccountList?.forEach(({ alias_type, is_remember }) => {
        exposeSingleAccount({ alias_type, is_remember })
      })
    } catch (err){
      console.error(err + '')
    }
  }
  if(historyAccountList?.length > 1){
    try{
      historyAccountList?.forEach(({ alias_type, is_remember }) => {
        exposeMultiAccount({ alias_type, is_remember })
      })
    } catch (err){
      console.error(err + '')
    }
  }
}

const initData = () => {
  if(isAsComponent.value) return
  if(instancename.value == 'relationOrder') return
  exposeEvents()
}

onMounted(() =>{
  setTimeout(() => {
    initData()
  }, 0)
})

defineExpose({
  handleShowDeleteModal
})
// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'AccountList'
}
</script>

<style lang="less" scoped>
.account-list__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .multi-account__title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .multi-account__text{
    color: @sui_color_gray_dark3;
    font-size: 12px;
    margin-bottom: 16px;
  }
  .multi-account__limit{
    color: @sui_color_gray_dark3;
    font-size: 12px;
    align-self: flex-start;
  }
}

.flip-horizontal{
  transform: scaleX(-1);
}

.multi-account__avatar{
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  .account-icon{
    width: 45px;
    height: 45px;
  }
  .third-login__icon{
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.multi-account__list {
  width: 100%;
  max-height: 318px;
  margin-bottom: 12px;
  overflow-y: scroll;

  .multi-account__list_item {
    padding: 8px 12px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 61px;
    border: 1px solid @sui_color_gray_weak1;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    .multi-account__desc {
      margin-left: 16px;
      flex: 1;
      color: @sui_color_main;
      font-size: 14px;
      text-align: left;
    }

    .remove__link {
      font-size: 12px;
      color: @sui_color_link;
      text-transform: capitalize;
    }
  }

  .multi-account__item_disable {
    cursor: not-allowed;

    .multi-account__desc {
      color: @sui_color_gray_light2;
    }
  }
}

.single-account__title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 9px;
}

.single-account__content {
  width: 100%;
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  text-align: center;

  .single-account__last_login {
    font-size: 12px;
    text-transform: capitalize;
    color: @sui_color_gray_dark2
  }

  .single-account__alias {
    font-size: 18px;
    font-weight: 700;
  }
}

.single-account__avatar{
  position: relative;
  margin: 12px 0;
  width: 60px;
  height: 60px;
  .account-icon{
    width: 60px;
    height: 60px;
  }
  .third-login__icon{
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.single-account__signIn_btn {
  margin-top: 24px;
}
</style>
