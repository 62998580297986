<template>
  <div class="multi-account__third_icon">
    <GoogleIcon
      v-if="aliasType == 3"
    />
    <FacebookIcon v-if="aliasType == 4" />
    <VKIcon v-if="aliasType == 7" />
    <LineIcon v-if="aliasType == 6" />
    <KakaoIcon v-if="aliasType == 8" />
    <NaverIcon v-if="aliasType == 9" />
  </div>
</template>

<script setup>
import GoogleIcon from '../Icon/ThirdGoogleIcon.vue'
import FacebookIcon from '../Icon/ThirdFacebookIcon.vue'
import VKIcon from '../Icon/ThirdVkIcon.vue'
import LineIcon from '../Icon/ThirdLineIcon.vue'
import KakaoIcon from '../Icon/ThirdKakaoIcon.vue'
import NaverIcon from '../Icon/ThirdNaverIcon.vue'

defineProps({
  aliasType: {
    type: Number || String,
    default: ''
  }
})


// eslint-disable-next-line semi
;
</script>

<script>
export default {
  name: 'ThirdLoginIcon'
}
</script>

<style lang="less" scoped>
.multi-account__third_icon{
  width: 16px;
  height: 16px;
}
</style>
