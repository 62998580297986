<template>
  <s-drawer
    :visible="newUIStates.showType == 'email'"
    class="page__login-newUI-emailPannel"
    type="full"
    direction="rtl"
    :no-header="true"
    :append-to-body="true"
    :immediately-render="true"
    da-expose-code="page-login-NewemailPannel"
  >
    <div class="main-content-body">
      <LoginHead
        :showback="true"
        :show-logo="false"
        @back="goBack"
      />
      <h2 v-show="!isInRelatedAcc">
        <p>{{ subTitle }}</p>
        <div class="sub-title">
          {{ guideText }}
        </div>
      </h2>
      <RelatedAccTip />
      <div class="main-content">
        <!-- 邮箱 -->
        <div class="input-filed">
          <login-input
            :value="newUIStates.alias"
            :label="langText.SHEIN_KEY_PWA_15325"
            ui-type="new"
            :disabled="true"
          >
            <template slot="label-icon">
              <s-popover
                trigger-type="click"
                placemen="top-start"
                theme="light"
                :append-to-body="false"
              >
                <p
                  class="page__login_emailPopover-link"
                  v-html="emailPopOverTips"
                ></p>
                <i
                  v-show="showEmailOverTip"
                  slot="reference"
                  class="suiiconfont sui_icon_caution_12px_2"
                  da-event-click="2-8-99"
                ></i>
              </s-popover>
            </template>
            <template slot="end">
              <Icon 
                name="sui_icon_edit_20px"
                size="20px"
                da-event-click="2-8-106" 
                data-type="email" 
                :data-scene="newUIStates.type" 
                @click="goBack('edit')"
              />
            </template>
          </login-input>
        </div>
        <!-- 密码 -->
        <div class="input-filed">
          <login-input
            v-model="password"
            type="password"
            :tips="passwordTip.txt || aliasTip.txt"
            :label="langText.SHEIN_KEY_PWA_15072"
            :mode="'email'"
            ui-type="new"
            @input="handlePasswordAliasChange"
          />
        </div>
        <!-- 注册密码提示 -->
        <div
          v-if="!safety.hideAll && newUIStates.type == 'signup'"
          class="page__login_password-regexp"
        >
          <div :class="{'normal-red' :safety.showAll}">
            <p :class="{'tips-greenp':safety.showFir}">
              · {{ langText.SHEIN_KEY_PWA_16140 }}
            </p>
            <p :class="{'tips-greenp':safety.showSec}">
              · {{ langText.SHEIN_KEY_PWA_16141 }}
            </p>
            <p :class="{'tips-greenp':safety.showThi}">
              · {{ langText.SHEIN_KEY_PWA_16142 }}
            </p>
            <p :class="{'tips-greenp':safety.showFour}">
              .  {{ langText.SHEIN_KEY_PWA_16892 }}
            </p>
          </div>
        </div>
        <!--忘记密码-->
        <div
          v-if="newUIStates.type == 'login'"
          class="mshe-text-right forgot-pass"
        >
          <a
            href="javascript:;"
            da-event-click="2-8-55"
            data-type="email" 
            @click="handleForgetPsd"
          >{{ langText.SHEIN_KEY_PWA_15582 }}</a>
        </div>
        <!-- 强合规隐私政策 && 订阅 -->
        <privacy-bottom
          type="email"
          @privacy-change="handlePrivacy"
          @subscrip-change="handleSubscrib"
        />
        <!--Google人机验证-->
        <div class="c-form-group">
          <div
            v-show="isShowGoogleRecaptcha"
            ref="loginRecaptchaVnode"
          ></div>
          <p
            v-show="isShowGoogleRecaptchaErr"
            class="sign-in-verification-error text-error"
          >
            Verification has failed. Please try again.
          </p>
        </div>
        <!-- continue操作按钮 -->
        <div class="actions">
          <LoginPointButton
            v-if="newUIStates.type == 'login'"
            :text="langText?.SHEIN_KEY_PWA_15578"
            :langText="langText"
            :configData="LoginButtonPoint"
            :type="['primary', 'H88PX']"
            @click="handleEmailLogin"
          />
          <LoginPointButton
            v-if="newUIStates.type == 'signup'"
            :text="langText?.SHEIN_KEY_PWA_15579"
            :langText="langText"
            :configData="LoginButtonPoint"
            :type="['primary', 'H88PX']"
            @click="handleEmailSignup"
          />
        </div>
        <!-- 新人权益 -->
        <NewUserRights
          v-if="newUIStates.type == 'signup'"
          scene="register"
          type="email"
        />
      </div>
    </div>
    <div class="main-content-footer">
      <!-- 弱合规隐私政策 -->
      <WeekPrivacy
        v-if="newPrivacyAbt.type == 'no'"
        :type="newUIStates.type"
        location="email_login"
      />
    </div>
    <!-- 风控验证 -->
    <TemporaryVue
      ref="temporaryRef"
      :langText="langText"
    />
  </s-drawer>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import LoginHead from './head.vue'
import WeekPrivacy from './week_privacy.vue'
import RelatedAccTip from '@login/components/RelatedAccount/RelatedAccountTip.vue'
import LoginPointButton from '@login/components/LoginPointButton/index.vue'
import TemporaryVue from '@login/components/MultiAccount/Temporary.vue'
import PrivacyBottom from '@login/components/PrivacyBottom/index.vue'
import NewUserRights from '@login/components/NewUserRights/index.vue'
import baseMixin from '@login/mixin/base_mixin'
import { Icon } from '@shein-aidc/icon-vue2'
import { checkEmail, checkSignUpEmail, formatErrorLoginInput, newPrivacyCache, formatErrorSignUpInput, querys, checkPw, sleep } from '@login/util'
import EmailLoginMixin from '@login/mixin/merge_email_mixin'
import EmailSignupMixin from '@login/mixin/merge_email_signup'
import { saSend } from '@login/analysis/util'
import { exposeEmailLoginSubcribeCheckbox } from '@login/utils/loginAnalysis/emailLogin.js'
import { getSessionStorage, removeSessionStorage } from '@shein/common-function'
import { Metric_Login_Detail_Click_Total, Metric_Register_Detail_Click_Total, Metric_Login_Detail_Click_Result_Total } from '@login/utils/monitorLog.js'
import { isLogin } from 'public/src/pages/common/utils/index.js'
 
const { langPath, IS_RW, SiteUID } = gbCommonInfo

export default {
  name: 'NewEmailPannel',
  components: {
    LoginHead,
    PrivacyBottom,
    NewUserRights,
    RelatedAccTip,
    WeekPrivacy,
    LoginPointButton,
    Icon,
    TemporaryVue
  },
  mixins: [ baseMixin, EmailLoginMixin, EmailSignupMixin ],
  data: () => ({
    IS_RW,
    aliasTip: { show: false, txt: '' },
    password: '',
    passwordTip: { show: false, txt: '' },
    checkboxPrivacy: false,
    checkEmailSubscrib: false,
  }),
  computed: {
    ...mapState('login', ['langText', 'newUIStates', 'WEB_CLIENT', 'newPrivacyAbt', 'commonAbt', 'defaultLocation', 'relatedAcc', 'isShowRetainModal', 'RetainModalConfig', 'showPointRetainModal', 'closeRetainModal', 'LoginButtonPoint', 'online']),
    subTitle () {
      const type = this.newUIStates.type
      if (type == 'login') {
        return this.langText.SHEIN_KEY_PWA_34385
      }
      if (type == 'signup') {
        return this.langText.SHEIN_KEY_PWA_34383
      }
      return ''
    },
    guideText () {
      const type = this.newUIStates.type
      if (type == 'signup') {
        return this.langText.SHEIN_KEY_PWA_34384
      }
      return this.langText.SHEIN_KEY_PWA_34386
    },
    // 是否是在展示关联账号, 关联账号模式需要隐藏非必要元素
    isInRelatedAcc () {
      return this.relatedAcc.global
    },
    emailPopOverTips () {
      return this.langText?.SHEIN_KEY_PWA_23126?.replace('{0}', this.WEB_CLIENT?.toUpperCase()).replace('{1}', `<a href="${langPath}/Unsubscribe-from-marketing-emails-a-1051.html" target="_blank">${this.langText.SHEIN_KEY_PWA_23127}</a>`) || ''
    },
    showEmailOverTip () {
      return this.commonAbt.SignupSubscribeNote && this.newUIStates.type == 'signup'
    }
  },
  watch: {
    'newUIStates.showType': {
      handler(val){
        if(val == 'email'){
          removeSessionStorage('checkboxPrivacy')
          const subscribe_status = this.newUIStates.subscribe_status || ''
          const subcribe_type = this.SIGNUP_SUBSCRIPTION_NO_SUPPORT
          if(subscribe_status){
            exposeEmailLoginSubcribeCheckbox(subcribe_type)
          }
        }
      }, immediate: true
    },
    closeRetainModal: {
      handler(val){
        if(val == 'email') {
          this.handleEmailGoBack()
        }
      }, immediate: true
    },
    
  },
  methods: {
    ...mapMutations('login', ['setnewUIStates', 'setForgetPassword', 'assignState', 'setRegisterPrivacyModal']),
    showInputTips (tip, msg, show = true) {
      tip.show = show
      tip.txt = msg
    },
    handleForgetPsd () {
      if (!this.online) {
        this.$toast(this.langText.SHEIN_KEY_PWA_31762)
        return
      }
      const { alias } = this.newUIStates
      this.setForgetPassword({ show: true, email: alias, type: 'email' })
    },
    handlePasswordAliasChange () {
      this.showInputTips(this.passwordTip, '')
      if (this.newUIStates.type == 'signup') {
        this.checkPasswordRank()
      }
    },
    handleCloseRetainModal (from) {
      if(from == 'edit') return
      if(isLogin()) return
      const LoginRegisterRetian = this.commonAbt?.LoginRegisterRetian
      const LoginRegisterRetianPops = this.commonAbt?.LoginRegisterRetianPops
      if (LoginRegisterRetian == 'on' && this.isShowRetainModal != true && !this.IS_RW) {
        const result = LoginRegisterRetianPops.split(',')
        const cacheRetainModal = getSessionStorage('cacheRetainModal') || []
        if (cacheRetainModal.includes('newUser') || this.RetainModalConfig.type != 'newUser') return
        const type = this.RetainModalConfig.type
        if (result.includes(type) && !cacheRetainModal.includes(type)) {
          this.assignState({
            showPointRetainModal: true,
          })
          if (!cacheRetainModal.includes(this.RetainModalConfig.type)) {
            this.assignState({
              isShowRetainModal: true
            })
          }
        }
      }
    },
    goBack (from) {
      if(!this.newUIStates.isToLogin){
        this.handleCloseRetainModal(from)
      }
      sleep(0)
      if (this.showPointRetainModal == true) {
        return
      }
      this.handleEmailGoBack()
    },
    handleEmailGoBack(){
      this.setnewUIStates({ showType: 'continue' })
      this.password = ''
      this.showInputTips(this.aliasTip, '')
      this.showInputTips(this.passwordTip, '')
    },
    handlePrivacy (v) {
      this.checkboxPrivacy = v
      this.assignState({
        checkboxPrivacyValue: this.checkboxPrivacy
      })
    },
    handleSubscrib (v) {
      this.checkEmailSubscrib = v
    },
    // 邮箱登陆场景
    handleEmailLogin() {
      const { alias: email, locationRiskId } = this.newUIStates
      const pwd = this.password
      Metric_Login_Detail_Click_Total({ account_type: 'email', sub_site: SiteUID || '' })
      // 验证邮箱和密码是否合法
      if (!checkEmail(email, this.aliasTip) || !checkPw(pwd, this.passwordTip)) {
        const samsg = formatErrorLoginInput(email, pwd)
        const subscribe_status = this.newUIStates.subscribe_status || ''
        const subcribe_type = subscribe_status && this.SIGNUP_SUBSCRIPTION_NO_SUPPORT ? 'default_not_subcribe' : 'default_subcribe'
        const subcribe_check = subscribe_status && this.checkboxSubscrib ? 1 : 0
        const saObj = {
          mode: 'email',
          result_reason: samsg,
          acc: this.relatedAcc
        }
        subscribe_status ? Object.assign(saObj, { subcribe_type, subcribe_check }) : ''
        saSend('2-8-108', saObj)
        Metric_Login_Detail_Click_Result_Total({ account_type: 'email', sub_site: SiteUID || '', result: 'failed', error: samsg  })
        return
      }

      this.$evt.$emit('event-loading', true)

      this.isDisableSubmitBtn = true

      const { act_name } = querys()
      const params = { email, password: pwd, biz_uuid: locationRiskId, daId: '2-8-108', checkboxSubscrib: this.checkEmailSubscrib, }

      // 活动参数
      if (act_name) params.activity_name = act_name

      // 默认订阅，勾选表示不订阅, 不勾选表示订阅
      if (this.SIGNUP_SUBSCRIPTION_SUPPORT && !this.checkEmailSubscrib) {
        params.is_subscribe = 1
      }
      // 默认不订阅，勾选表示订阅
      if (this.SIGNUP_SUBSCRIPTION_NO_SUPPORT && this.checkEmailSubscrib) {
        params.is_subscribe = 1
      }

      // 谷歌人机验证元素
      const $googleRecaptcha = document.getElementById('g-recaptcha-response')

      if ($googleRecaptcha && $googleRecaptcha.value) params.g_recaptcha_response = $googleRecaptcha.value

      params.clause_flag = 0
      if (this.newPrivacyAbt.type == 'yes') {
        params.clause_flag = 1
        if (newPrivacyCache.get(this.defaultLocation?.forceId || this.defaultLocation?.id || '')) {
          params.clause_agree = 1
        }
      }

      // 一站多合规，需要传地区id
      params.locationId = this.newPrivacyAbt.origin == 'mix' ? (this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
      
      // 调用EmailLoginMixin里面的 requestEmailLogin 方法
      this.requestEmailLogin(params)

    },
    // 邮箱注册场景
    handleEmailSignup() {
      const { alias: email, locationRiskId } = this.newUIStates
      const password = this.password
      Metric_Register_Detail_Click_Total({ account_type: 'email', sub_site: SiteUID || '' })  
      // 检测邮箱和密码的完整性
      if (!checkSignUpEmail(email, this.aliasTip) || !this.checkPsd(password)) {
        const samsg = formatErrorSignUpInput(email, password)
        saSend('2-8-107', {
          mode: 'email',
          result_reason: samsg,
          acc: this.relatedAcc,
          ...this.getSubscribeSaParams('2-8-107')
        })
        return
      }
      const params = { email, password, biz_uuid: locationRiskId, daId: '2-8-107', checkboxSubscrib: this.checkEmailSubscrib }
      params.locationId = this.newPrivacyAbt.origin == 'mix' ? (this.defaultLocation?.forceId || this.defaultLocation?.id || '') : ''
      // 默认订阅，勾选表示不订阅, 不勾选表示订阅
      if (this.SIGNUP_SUBSCRIPTION_SUPPORT && !this.checkEmailSubscrib) {
        params.is_subscribe = 1
      }
      // 默认不订阅，勾选表示订阅
      if (this.SIGNUP_SUBSCRIPTION_NO_SUPPORT && this.checkEmailSubscrib) {
        params.is_subscribe = 1
      }
      params.clause_flag = 0
      if (this.newPrivacyAbt.type == 'yes') {
        if (!this.checkboxPrivacy) {
          if (SiteUID !== 'mkr') {
            this.setRegisterPrivacyModal({
              cb: () => {
                this.$evt.$emit('event-loading', true)
                this.assignState({
                  checkboxPrivacyValue: true
                })
                this.handlePrivacy(true)
                params.clause_flag = 1
                params.clause_agree = 1
                this.requestEmailSignupSer(params)
              }
            })
          } else {
            this.$toast(this.langText.SHEIN_KEY_PWA_31878)
          }
          return
        }
        params.clause_flag = 1
        params.clause_agree = 1
      }
      this.isDisableSubmitBtn = true
      this.$evt.$emit('event-loading', true)
      const isAgree = getSessionStorage('checkboxPrivacy') || ''
      if(isAgree == true) {
        params.clause_flag = 1
        params.clause_agree = 1
      }
      // 调用EmailSignupMixin里面的 requestEmailSignupSer 方法
      this.requestEmailSignupSer(params)
    },
  },
}
</script>

<style lang="less">
.page__login-newUI-emailPannel {
  .S-drawer__body {
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    .main-content-body {
      flex: 1 1 100%;
    }
    .main-content-fotoer {
      flex: 0 0 auto;
    }
  }
  h2 {
    padding: 16px 0 8px 0;
    .font-dpr(36px);
    line-height: 1;
    .sub-title {
      font-size: 14px;
      color: @sui_color_gray_light1;
      font-weight: normal; 
      padding-top: 4px;
      text-transform: none;
    }
  }
  .forgot-pass {
    padding: 12px 0;
    a {
      text-decoration: none;
    }
  }
  .actions {
    padding-top: 16px;
    button {
      width: 100%;
    }
  }
  .page__login_password-regexp {
    margin-top: 4px;
  }
  .page-login__privacy-bottom {
    padding-top: 2px;
  }
  .page__login-inputComp_disabled {
    .page__login-inputComp_con {
      background: @sui_color_gray_weak2;
      border-color: transparent;
      
    }
  }
  .page__login_related-accTips {
    padding: 0;
    margin: 27px 0 16px 0;
  }
  .page__login-weekPrivacy {
    position: relative;
    bottom: 0;
    padding: 0;
    padding-bottom: 10px;
  }
}
</style>
