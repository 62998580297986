<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8Z"
      fill="#3949AB"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.72069 12.1818V8.09043H9.85025L9.99994 6.68051H8.72069L8.72261 5.97484C8.72261 5.60711 8.75755 5.41007 9.28579 5.41007H9.99195V4H8.86222C7.50524 4 7.02762 4.68396 7.02762 5.83417V6.68067H6.18176V8.09059H7.02762V12.1818H8.72069Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'ThirdFacebookIcon'
}
</script>
